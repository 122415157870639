import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { chakra, Box, Container, Text, useColorModeValue } from '@chakra-ui/react';

import { LinkTranslate } from 'components/navigation';

const PrefaceSection = ({ id }) => {
    const { t } = useTranslation();
    return (<Box
        as="section"
        id={id && id}
        bgColor={useColorModeValue('gray.25', 'whiteAlpha.50')}
        py={3}>
        <Container maxW="container.xl" my={3}>
            <chakra.h1 align="center">{t('profile_title')}</chakra.h1>
            <Text size="lg" align="center" m="auto" maxW="container.sm">
                <Trans i18nKey="profile_body" t={t} components={[
                    <LinkTranslate to="/info/business" />,
                    <LinkTranslate to="https://www.linkedin.com/in/richard-sd/" />
                ]} />
            </Text>
        </Container>
    </Box>);
};

export default PrefaceSection;
