import React from 'react';
import {
    Stat,
    StatLabel,
    StatNumber,
    Highlight,
    useColorModeValue,
} from '@chakra-ui/react';

const StatsCard = ({ id, title, stat, isHighlighted }) => {
    const alternativeColor = useColorModeValue('black', 'white');
    const color = id % 2 === 0 ? 'primary.300' : alternativeColor;
    const highlightStyle = { px: '2', py: '1', rounded: 'lg', color: 'white', bg: 'primary.300' };

    return (
        <Stat
            px={{ base: 4, md: 8 }}
            align="center"
            rounded="lg"
            gap={6}
            color={color}
            /*_odd={{ color: 'primary.300' }}
            _even={{ color: useColorModeValue('black', 'white') }}*/>
            <StatNumber
                // color = handled in element above this one due
                // the need to satisfy selector requirements.
                fontWeight="bold"
                fontSize="2xl"
                mb={1}>
                {isHighlighted && <Highlight
                    query={[stat.split(' ')[0]]}
                    styles={highlightStyle}>{stat}</Highlight>}
                {!isHighlighted && stat}
            </StatNumber>
            <StatLabel
                color="gray.400"
                fontWeight="normal"
                fontSize="md">
                {title}
            </StatLabel>
        </Stat>
    );
}

export default StatsCard;
