import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import { Container, Box, Image, useColorModeValue } from '@chakra-ui/react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { MetaData } from 'components/meta';
import { SiteHero } from 'components/view';
import { DefaultLayout, SwitchComponent, ForceRatio } from 'components/layout';
import { UspSection, PrefaceSection, ServiceOfferSection } from 'components/section/index';

import { flattenFrontmatterData } from 'utils/hoc/mdx';

const DesktopHeader = (props) => <SiteHero pt="3.5rem" {...props} />;

const MobileHeader = (props) => {
    const gradient = 'linear-gradient(132deg, rgb(251, 165, 116) 0.00%, rgb(216, 245, 251) 100.00%)';

    return (<Container
        bgGradient={useColorModeValue(gradient, 'inherit')}
        bgColor={useColorModeValue('inherit', 'whiteAlpha.50')}
        {...props}>
        <ForceRatio
            maxW="full"
            ratio={1}>
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                m={12}>
                <Image src="/images/logo/logo-cheesebyte-512px.png" />
            </Box>
        </ForceRatio>
    </Container>);
};

/**
 * Main index page (home page).
 */
const Index = ({ data, location }) => {
    const { language } = useI18next();
    const { allService } = data;

    const [serviceItems, setServiceItems] = React.useState([{}]);

    React.useEffect(() => {
        const fetchItems = async () => {
            // Map to make services an array with a flat object containing: { id, title, template, language, etc }
            const items = flattenFrontmatterData(allService, language);
            setServiceItems(items);
        };

        fetchItems().catch(console.error);
    }, [allService, language]);

    return (<>
        <MetaData location={location} />
        <DefaultLayout>
            <SwitchComponent first={MobileHeader} second={DesktopHeader} />
            <UspSection />
            <PrefaceSection id="preface" />
            {/* <TestimonialSection id="testimonial" /> */}
            <ServiceOfferSection serviceItems={serviceItems} />
        </DefaultLayout>
    </>)
};

Index.propTypes = {
    data: PropTypes.shape({
        allService: PropTypes.object.isRequired,
        locales: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default Index;

export const pageQuery = graphql`
query PageQuery($language: String!) {
    allService: allMdx(
        filter: {
            frontmatter: {
                template: {
                    eq: "Service"
                }
            }
        },
        sort: {
            fields: frontmatter___title,
            order: ASC
        }) { ...MdxServiceFullFragment }
    locales: allLocale(
        filter: {
            language: {
                eq: $language
            },
            ns: {
                in: ["common", "index"]
            }
        }) { ...TranslationFragment }
}`;
