import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, SimpleGrid } from '@chakra-ui/react';

import { Carousel, SwitchComponent } from 'components/layout';
import { StatsCard } from 'components/section/index';

const UspSection = () => {
    const { t } = useTranslation();
    const uspItems = [
        { title: t('usp_work_exp_desc'), description: t('usp_work_exp_title') },
        { title: t('usp_data_exp_desc'), description: t('usp_data_exp_title') },
        { title: t('usp_stable_desc'), description: t('usp_stable_title') },
        { title: t('usp_award_desc'), description: t('usp_award_title') },
        { title: t('usp_apps_desc'), description: t('usp_apps_title') },
    ];

    const mobileLayout = () => <Carousel
        maxW="3xl"
        mx="auto"
        px={3}
        timeout={2000}>
        {uspItems.map((item, id) => (
            <StatsCard
                id={id}
                key={id}
                title={item.title}
                stat={item.description} />
        ))}
    </Carousel>;

    const desktopLayout = () => <SimpleGrid
        py={6}
        columns={5}
        spacing={6}>
        {uspItems.map((item, id) => (
            <StatsCard
                id={id}
                key={id}
                title={item.title}
                stat={item.description} />
        ))}
    </SimpleGrid>;

    return (<Container
        as="section"
        id="unique-selling-points"
        maxW="container.xl"
        my={6}>
        <SwitchComponent
            breakpoint="lg"
            first={mobileLayout}
            second={desktopLayout} />
    </Container>);
};

export default UspSection;
