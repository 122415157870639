import React from 'react';
import { useI18next, Trans } from 'gatsby-plugin-react-i18next';
import { chakra, Box, Container, Text, Link as ChakraLink, useColorModeValue } from '@chakra-ui/react';

import { LinkTranslate } from 'components/navigation';
import { SlideScroller } from 'components/layout';
import { TaggedPreview } from 'components/view';

import { useColorMapping } from 'utils/hook/useColorMapping';
import { SiteConfig } from 'utils/config';

const detailItemColors = [
    'secondary.300',
    'gray.500',
    'tertiary.600',
    'primary.300',
];

const ServiceOfferButton = ({ id, item }) => {
    const { language } = useI18next();
    const [mappedColors] = useColorMapping(detailItemColors[id % detailItemColors.length])

    // Should not build a href here, but custom Link component is not quite up to the
    // task yet without getting it into an unhealthy state of code. Needs refactor.
    return (<ChakraLink
        variant="unstyled"
        href={`${language}${item.path}`}>
        <TaggedPreview
            w="12em"
            h="14em"
            colors={mappedColors}
            {...item}
        />
    </ChakraLink>);
};

const ServiceOfferSection = ({ id, serviceItems }) => {
    const { t } = useI18next();
    return (<Box
        as="section"
        id={id}
        // bgColor={useColorModeValue('gray.25', 'whiteAlpha.50')}
        py={3}
        pb={6}>
        <Container
            maxW="container.xl"
            my={3}>
            <chakra.h1
                align="center"
                my={3}
                color={useColorModeValue('black', 'white')}>
                {t('service_offer_title')}
            </chakra.h1>
            <Text
                size="lg"
                align="center"
                m="auto"
                maxW="container.sm">
                <Trans i18nKey="service_offer_body" t={t} components={[
                    <LinkTranslate
                        to={`mailto:${SiteConfig.siteUseEmailAddress}?subject=${t('service_offer_email_subject')}`}
                        isExternal={true} />
                ]} />
            </Text>
            <SlideScroller mt={6}>
                {serviceItems
                    ?.map((singleServiceItem, index) =>
                        <ServiceOfferButton
                            key={index}
                            id={index}
                            item={singleServiceItem} />
                )}
            </SlideScroller>
        </Container>
    </Box>)
};

export default ServiceOfferSection;
